import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getLabels = async (data) => {
  try {
    const orgId = JSON.parse(localStorage.getItem("user")).u_default_org_fk;
    if (orgId) {
      const url = `${API_URL}/org_labels/${orgId}`;
      const response = await axios.get(url, data);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getLabelOptions = async (page, size) => {
  try {
    const url = `${API_URL}/org_label_options?page=${page}&pageSize=${size}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveOrgLabel = async (data) => {
  try {
    const org_id = JSON.parse(localStorage.getItem("user")).u_default_org_fk;
    const user_id = JSON.parse(localStorage.getItem("user")).u_id;
    const url = `${API_URL}/org_labels`;
    const response = await axios.post(url, { ...data, org_id, user_id });
    return response;
  } catch (error) {
    throw error;
  }
};