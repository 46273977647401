import {
  LOGIN_CANDIDATE_SUCCESS,
  GET_CANDIDATE_DASHBOARD_SUCCESS,
  START_INTERVIEW_SUCCESS,
  SUBMIT_INTERVIEW_QUESTION_SUCCESS,
  END_INTERVIEW_SUCCESS,
  GET_CANDIDATES_SUCCESS,
  SEND_INTERVIEW_INVITE_SUCCESS,
  ADD_HR_REMARKS_SUCCESS,
  GET_CANDIDATES_BY_BATCHID_SUCCESS,
  UPDATE_CANDIDATE_SUCCESS,
  CLEAR_CANDIDATES,
  GET_INTERVIEW_STATUS_SUCCESS,
  GET_CANDIDATES_JD_SUCCESS,
  GET_QA_BY_THREADID_SUCCESS,
  GET_JOBSLIST_SUCCESS,
  UPDATE_USER_CONSENT
} from "./types";
import {
  _loginCandidate,
  _getCandDashboard,
  _startCandInterview,
  _submitCandInterviewQuestion,
  _endCandInterview,
  _getCandidates,
  _sendInvite,
  _submitAiAns,
  _endInt,
  _addHrRemarks,
  _getCandidatesByBatchId,
  _releaseOffer,
  _scheduleSecRound,
  _updateCandidate,
  _getInterviewStatus,
  _getQAs,
  _getCandidatesFilter,
  _getJobsList,
  _giveUserConsent
} from "../../services/candidate.service";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";
import { getApplicant } from "./applicant";

export const loginCandidate = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _loginCandidate(data);

    if (response.status === 200) {
      dispatch({
        type: LOGIN_CANDIDATE_SUCCESS,
        payload: response.data,
      });

      // localStorage.setItem("candidate", JSON.stringify(response.data));

      dispatch(setMessage("Logged in successfully!"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("Error in signin user", error);

    dispatch(hideLoading());
  }
};
export const updateCandidate = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _updateCandidate(data, id);

    if (response.status === 200) {
      dispatch({
        type: UPDATE_CANDIDATE_SUCCESS,
      });

      dispatch(setMessage("Info updated successfully!"));
      dispatch(getApplicant(id));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("Error in updating candidate", error);

    dispatch(hideLoading());
  }
};

export const startInterview = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _startCandInterview(id);

    if (response.status === 200) {
      dispatch({
        type: START_INTERVIEW_SUCCESS,
        payload: response.data,
      });

      dispatch(setMessage("Interview started successfully!"));
    }else{
      alert("Failed to start interview. Please try again.");
      window.location.reload();
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("Error in start interview", error);
    alert("Failed to start interview. Please try again.");
    window.location.reload();
    dispatch(hideLoading());
  }
};

export const submitAIanswer = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _submitCandInterviewQuestion(data);
    if (response.status === 200) {
      dispatch({
        type: SUBMIT_INTERVIEW_QUESTION_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("Answer submitted successfully!"));
    } else {
      // dispatch({
      //   type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
      // });
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());

    console.error("error in uploading cv in AI Interview", error);
    // dispatch({
    //   type: SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
    // });
  }
};

export const concludeInt = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _endCandInterview(data);
    if (response.status === 200) {
      dispatch({
        type: END_INTERVIEW_SUCCESS,
      });
      dispatch(setMessage("Interview concluded successfully!"));
    } else {
      dispatch(setMessage("There is some issue, please try again!"));
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(setMessage("There is some issue, please try again!"));
    console.error("error in uploading cv in AI Interview", error);
  }
};

export const getInterviewStatus = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getInterviewStatus(id);

    if (response.status === 200) {
      dispatch({
        type: GET_INTERVIEW_STATUS_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("Error in start interview", error);

    dispatch(hideLoading());
  }
};

export const getCandidates = (jb_id, page, size, filters, search) => async (dispatch) => {
  try {
    // dispatch({
    //   type: CLEAR_CANDIDATES,
    // });
    dispatch(showLoading());
    const response = await _getCandidates(jb_id, page, size, filters, search);

    if (response.status === 200) {
      if (jb_id) {
        dispatch({
          type: GET_CANDIDATES_JD_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_CANDIDATES_SUCCESS,
          payload: response.data,
        });
      }
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting condidates", error);
    dispatch(hideLoading());
  }
};

export const getJobsList = () => async (dispatch) => { 
  try {
    dispatch(showLoading());
    const response = await _getJobsList();

    if (response.status === 200) {
      dispatch({
        type: GET_JOBSLIST_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting jobs list", error);
    dispatch(hideLoading());
  }
}


export const getQAs = (candidateId, threadId) => async (dispatch) => {
  try {
    // dispatch({
    //   type: CLEAR_CANDIDATES,
    // });
    dispatch(showLoading());
    const response = await _getQAs(candidateId, threadId);

    if (response.status === 200) {
      dispatch({
        type: GET_QA_BY_THREADID_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting condidates", error);
    dispatch(hideLoading());
  }
};

export const getCandidatesByBatchId =
  (jb_id, page, size) => async (dispatch) => {
    try {
      dispatch(showLoading());

      setTimeout(() => {}, 2000);
      const response = await _getCandidatesByBatchId(jb_id, page, size);

      if (response.status === 200) {
        dispatch({
          type: GET_CANDIDATES_BY_BATCHID_SUCCESS,
          payload: response.data,
        });
      }
      dispatch(hideLoading());
    } catch (error) {
      console.error("error in getting condidates", error);
      dispatch(hideLoading());
    }
  };

export const sendInvite =
  (id, job_id, batchId, page, size, bodyData) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await _sendInvite(id, bodyData);

      if (response.status === 200) {
        dispatch(setMessage("Interview invite sent successfully!"));
        if (batchId) {
          dispatch(getCandidatesByBatchId(batchId, page, size));
        } else {
          dispatch(getCandidates(job_id, page, size));
        }
      }
      dispatch(hideLoading());
    } catch (error) {
      console.error("Error in sending interview invite", error);

      dispatch(hideLoading());
    }
  };


export const sendInviteFromCandidateprofile =
  (id, body) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await _sendInvite(id, body);

      if (response.status === 200) {
        dispatch(setMessage("Interview invite sent successfully!"));
        dispatch(getApplicant(id));
      } 
      dispatch(hideLoading());
    } catch (error) {
      console.error("Error in sending interview invite", error);
      dispatch(setMessage("Email ID is required or Domain is not valid!"));
      dispatch(hideLoading());
    }
  };



export const addHrRemarks =
  (data, jb_id, batchId, page, size) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await _addHrRemarks(data);
      if (response.status === 200) {
        dispatch({
          type: ADD_HR_REMARKS_SUCCESS,
          payload: response.data,
        });
        if (jb_id) {
          dispatch(getCandidates(jb_id, page, size));
        } else {
          dispatch(getCandidatesByBatchId(batchId, page, size));
        }
        dispatch(setMessage("HR Remarks added successfully!"));
      }

      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      dispatch(setMessage("There is some issue, please try again!"));
      console.error("addHrRemarks", error);
    }
  };

export const scheduleSecRound = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _scheduleSecRound(data);
    if (response.status === 200) {
      dispatch(setMessage("Interview Scheduled successfully!"));
    }

    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(setMessage("There is some issue, please try again!"));
    console.error("scheduleSecRound", error);
  }
};

export const releaseOffer =
  (id, jb_id, batchId, page, size) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await _releaseOffer(id);
      if (response.status === 200) {
        dispatch({
          type: ADD_HR_REMARKS_SUCCESS,
          payload: response.data,
        });
        if (jb_id) {
          dispatch(getCandidates(jb_id, page, size));
        } else {
          dispatch(getCandidatesByBatchId(batchId, page, size));
        }

        // dispatch(setMessage("HR Remarks added successfully!"));
      }

      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      dispatch(setMessage("There is some issue, please try again!"));
      console.error("releaseOffer", error);
    }
  };

export const giveConsent =
  (id) => async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await _giveUserConsent(id);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_USER_CONSENT,
          payload: response.data,
        });

        // dispatch(setMessage("HR Remarks added successfully!"));
      }

      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      dispatch(setMessage("There is some issue, please try again!"));
      console.error("giveConsent", error);
    }
  };
