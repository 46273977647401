import { _getAllAclGroups, _getUserList, addModulePermissionApi, getPermissionListApi, savePermissionsApi } from "../../services/acls.service";
import { hideLoading, showLoading } from "./loader";
import { setMessage } from "./message";
import { GET_ALL_ACL_GROUPS, GET_PERMISSIONS_SUCCESS, GET_USERS_BY_GROUP_SUCCESS, SAVE_PERMISSIONS_SUCCESS } from "./types";

export const getAllAclGroups = () => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _getAllAclGroups();
        dispatch({
            type: GET_ALL_ACL_GROUPS,
            payload: response.data,
        });
        dispatch(hideLoading());
    } catch (e) {
        dispatch(setMessage(e.error.message));
        dispatch(hideLoading());
    }
}

export const getUserList = (grpId) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await _getUserList(grpId);
        dispatch({
            type: GET_USERS_BY_GROUP_SUCCESS,
            payload: response.data,
        });
        dispatch(hideLoading());
    } catch (e) {
        dispatch(setMessage(e.response.data.error));
        dispatch(hideLoading());
    }
}

export const savePermissions = (data) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await savePermissionsApi(data);

        if (response.status === 200) {
            dispatch({
                type: SAVE_PERMISSIONS_SUCCESS,
                payload: response.data,
            });
            dispatch(setMessage("Permissions saved successfully!", "success"));
        }
        dispatch(hideLoading());
    } catch (error) {
        console.error("error in creating job", error.response.data.error);
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
};

export const getPermissionList = (groupId) => async (dispatch) => {
    try {
        dispatch(showLoading());
        const response = await getPermissionListApi(groupId);

        if (response.status === 200) {
            dispatch({
                type: GET_PERMISSIONS_SUCCESS,
                payload: response.data,
            });
        }
        dispatch(hideLoading());
    } catch (error) {
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
}

export const createModulePermission = (data, editItem) => async (dispatch, getState) => {
    try {
        dispatch(showLoading());
        const response = await addModulePermissionApi(data, editItem);
        if (response.status === 201) {
            const { permission } = getState();
            dispatch({
                type: GET_PERMISSIONS_SUCCESS,
                payload: [...permission.permissions, response.data],
            });
            dispatch(hideLoading());
            dispatch(setMessage("Permissions added successfully!", "success"));
        } else {
            dispatch(hideLoading());
            dispatch(setMessage("Permissions added successfully!", "success"));
        }

    } catch (error) {
        dispatch(hideLoading());
        dispatch(setMessage(error.response.data.error, "error"));
    }
}