import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getAppOrgLabels = async () => {
  try {
    const orgId = JSON.parse(localStorage.getItem("user")).u_default_org_fk;
    if (orgId) {
      const url = `${API_URL}/app_org_labels/${orgId}`;
      const response = await axios.get(url);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

