import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../pages/hr/Dashboard.jsx";
import Jobs from "../pages/hr/Jobs.jsx";
import AddJob from "../pages/hr/AddJob.jsx";
import Batches from "../pages/hr/Batches.jsx";
import Candidates from "../pages/hr/Candidates.jsx";
import CandidateProfile from "../pages/hr/CandidateProfile.jsx";
import CreateBatch from "../pages/hr/CreateBatch.jsx";
import Interviews from "../pages/hr/Interviews.jsx";
import Employees from "../pages/hr/Employees.jsx";
import UsersProfile from "../pages/common/UsersProfile.jsx";
import JobDetails from "../pages/hr/JobDetails.jsx";
import BatchDetails from "../pages/hr/BatchDetails.jsx";
import Setting from "../pages/common/Setting.jsx";
import Skills from "../pages/hr/Skills.jsx";
import CandidateDetails from "../pages/hr/CandidateDetails.jsx";
import AiScoreReport from "../pages/hr/AiScoreReport.jsx";
import InterviewDetails from "../pages/hr/InterviewDetail.jsx";
import EmployeeProfile from "../pages/hr/EmployeeProfile.jsx";
import Label from "../pages/hr/Label.jsx";
import AccessControlManagement from "../pages/hr/AccessControlManagemen.jsx";
const HRRoutes = () => {
  return (
    <Routes>
      <Route path="/hr/dashboard" element={<Dashboard />} />
      <Route path="/hr/jd-list" element={<Jobs />} />
      <Route path="/hr/jd-detail/:jd_id" element={<JobDetails />} />
      <Route path="/hr/jd-list/create-jd" element={<AddJob />} />
      <Route path="/hr/batches" element={<Batches />} />
      <Route
        path="/hr/batches/batch-detail/:batch_id"
        element={<BatchDetails />}
      />
      <Route path="/hr/batches/create-batch/:jd_id" element={<CreateBatch />} />
      <Route path="/hr/candidates" element={<Candidates />} />
      <Route
        path="/hr/candidates/candidate-profile/:candidateId"
        element={<CandidateProfile />}
      />
      <Route
        path="/hr/candidates/candidate-details/:candidateId"
        element={<CandidateDetails />}
      />
      <Route
        path="/hr/candidates/interview-details/:candidateId/:threadId"
        element={<InterviewDetails />}
      />
      <Route
        path="/hr/employees/candidate-details/:candidateId"
        element={<CandidateDetails />}
      />
      <Route
        path="/hr/employees/employees-profile/:employeeId"
        element={<EmployeeProfile />}
      />
      <Route path="/hr/interviews" element={<Interviews />} />
      <Route path="/hr/skills" element={<Skills />} />
      <Route path="/hr/label" element={<Label />} />
      <Route path="/hr/employees" element={<Employees />} />
      <Route path="/hr/profile" element={<UsersProfile />} />
      <Route path="/hr/setting" element={<Setting />} />
      <Route path="/hr/ai_score/:apct_id" element={<AiScoreReport />} />
      <Route path="/hr/access-control" element={<AccessControlManagement />} />
      <Route path="*" element={<Navigate to="/hr/dashboard" replace />} />
    </Routes>
  );
};

export default HRRoutes;
