import axios from "axios";

const API_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

export const _getAllAclGroups = async () => {
  try {
    const url = `${API_URL}/acl/get-all-acl-groups`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _getUserList = async (grpId) => {
  try {
    const url = `${API_URL}/acl/get-acl-users-by-groupname/${grpId}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};


export const savePermissionsApi = async (data) => {
  try {
    const url = `${API_URL}/acl/update-acl-group/${data.selectedUser}`;
    const response = await axios.put(url, data);
    return response;

  } catch (error) {
    throw error;
  }
};

export const getPermissionListApi = async () => {
  try {
    const url = `${API_URL}/acl/get-all`;
    const response = await axios.get(url);
    return response;

  } catch (error) {
    throw error;
  }
};


export const addModulePermissionApi = async (data, editItem) => {
  try {
    const url = `${API_URL}/acl/create-acl`;
    const response = await axios.post(url, { data, editItem });
    return response;
  } catch (error) {
    throw error;
  }
};